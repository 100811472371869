import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'
import ButtonOrangeArrow, { Arrow } from 'components/elements/ButtonOrangeArrow'
import Image from 'components/shared/Image2'

// Third Party
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

interface DownloadsProps {
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Downloads
}

const StyledDownloads = styled.section``

const StyledBlogBanner = styled.section`
  background-color: ${(props) => props.theme.color.face.main};

  @media (min-width: 992px) {
    padding-top: 150px;
  }

  @media (max-width: 991px) {
    padding-top: 125px;
  }
`

const BlogBannerDescription = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & p,
  & strong {
    color: ${(props) => props.theme.color.text.light};
  }

  @media (min-width: 992px) {
    padding-bottom: 75px;
  }
`

//GAOF BZ56 2OBR ZK7Z UZ5S 7KYX ZV5O WX32

const BlogBanner: React.FC<DownloadsProps> = ({ fields }) => {
  return (
    <StyledBlogBanner className="pb-5">
      <div className="container">
        <div className="row">
          <div className={`col-lg-5 col-xl-4 pt-lg-5 pb-lg-5 mb-lg-5`}>
            <BlogBannerDescription content={fields.description} />
          </div>
        </div>
      </div>
    </StyledBlogBanner>
  )
}

const StyledBlogGrid = styled.section`
  position: relative;

  @media (min-width: 992px) {
    top: -150px;
  }

  @media (max-width: 991px) {
    top: -20px;
  }
`

const Downloads: React.FC<DownloadsProps> = ({ fields }) => (
  <StyledDownloads>
    <BlogBanner fields={fields} />
    <div className="container">
      <StyledBlogGrid className="my-1 mt-lg-5">
        <div className="container">
          <div className="row">
            {fields.downloads?.map((download, index) => (
              <div className="col-md-4 pb-lg-4 pb-3" key={index}>
                <Post big fields={download} />
              </div>
            ))}
          </div>
        </div>
      </StyledBlogGrid>
    </div>
  </StyledDownloads>
)

export default Downloads

const StyledPost = styled.a`
  display: block;
`

const PostMotion = styled(motion.div)<{ big: boolean }>`
  background-color: ${(props) => props.theme.color.face.background};
  border-radius: 25px;
  overflow: hidden;
  position: relative;
  z-index: 1;

  @media (min-width: 992px) {
    display: ${(props) => (props.big ? 'block' : 'flex')};
  }

  @media (max-width: 991px) {
    display: block;
  }
`

const PostImage = styled(Image)<{ big: boolean }>`
  @media (min-width: 992px) {
    height: ${(props) => (props.big ? '250' : '275')}px;

    ${(props) =>
      props.big &&
      css`
        width: 100%;
      `};
    ${(props) =>
      !props.big &&
      css`
        min-width: 50%;
        max-width: 50%;
      `};
  }

  @media (max-width: 991px) {
    height: 250px;
    width: 100%;
  }

  @media (max-width: 575px) {
    height: 175px;
  }
`

const PostDescription = styled(ParseContent)<{ big: boolean }>`
  color: ${({ theme }) => theme.color.text.purple};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-height: ${({ theme }) => theme.font.size.l};

  & h3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
    color: ${({ theme }) => theme.color.text.purple};

    @media (min-width: 992px) {
      font-size: ${(props) => props.theme.font.size['28']};
      height: 65px;
    }

    @media (max-width: 991px) {
      font-size: ${(props) => props.theme.font.size.xm};
      height: 50px;
    }
  }

  @media (min-width: 992px) {
    ${(props) =>
      props.big &&
      css`
        height: 70px;
        -webkit-line-clamp: 3;
      `};

    ${(props) =>
      !props.big &&
      css`
        height: 140px;
        -webkit-line-clamp: 6;
      `};
  }

  @media (max-width: 991px) {
    height: 100px;
    -webkit-line-clamp: 4;
  }
`

const PostLink = styled.a`
  color: ${({ theme }) => theme.color.text.purple};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.color.text.purple};
  }
`

const PostContent = styled.div<{ big: boolean }>`
  @media (min-width: 992px) {
    ${(props) =>
      !props.big &&
      css`
        max-width: 50%;
      `};
  }
`

interface PostProps {
  big?: boolean
  fields: GatsbyTypes.Maybe<GatsbyTypes.WpPage_Flexcontent_Flex_Downloads_downloads>
}

const Post: React.FC<PostProps> = ({ big = false, fields }) => {
  return (
    <StyledPost
      target="_blank"
      rel="norefferer noopener"
      href={fields?.download?.localFile?.publicURL}
    >
      <PostMotion
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        big={big}
      >
        <PostImage big={big} image={fields?.image} alt="AddComm" />
        <PostContent big={big} className="py-3 px-2 px-md-4">
          <PostDescription big={big} content={fields?.descrciption} />
          <div className="text-right mt-3">
            <PostLink
              target="_blank"
              rel="norefferer noopener"
              href={fields?.download?.localFile?.publicURL}
            >
              Bekijk hier
              <Arrow />
            </PostLink>
          </div>
        </PostContent>
      </PostMotion>
    </StyledPost>
  )
}
