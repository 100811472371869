import React from 'react'
import styled from 'styled-components'

// Elements
import Image from 'components/shared/Image2'
import ParseContent from 'components/shared/ParseContent'

const StyledBannerWithSmallImage = styled.section`
  background-color: ${(props) => props.theme.color.face.main};
  position: relative;
  z-index: 2;

  @media (min-width: 992px) {
    padding-top: 175px;
  }

  @media (max-width: 991px) {
    padding-top: 125px;
  }
`

const BannerWithSmallImageImage = styled(Image)`
  position: relative;
  z-index: 1;
  border-radius: 25px;
  overflow: hidden;

  & img {
    object-fit: cover !important;
  }

  @media (min-width: 992px) {
    width: 100%;
    max-height: 350px;
    min-height: 300px;
  }

  @media (max-width: 991px) {
    width: 100%;
    max-height: 250px;
  }

  @media (max-width: 767px) {
    max-width: 80%;
    max-height: 200px;
  }
`

const BannerWithSmallImageDescription = styled(ParseContent)`
  color: ${(props) => props.theme.color.text.light};

  & h2,
  & h1 {
    @media (min-width: 992px) {
      font-size: ${(props) => props.theme.font.size.xxl} !important;
    }

    @media (max-width: 991px) {
      font-size: ${(props) => props.theme.font.size.xl} !important;
    }
  }

  & h1 {
    @media (min-width: 992px) {
      line-height: ${(props) => props.theme.font.size.xxl} !important;
    }

    @media (max-width: 991px) {
      line-height: ${(props) => props.theme.font.size.xl} !important;
    }
  }
`

interface BannerWithSmallImageProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_BannerWithSmallImage
}

const BannerWithSmallImage: React.FC<BannerWithSmallImageProps> = ({
  fields,
}) => (
  <StyledBannerWithSmallImage>
    <div className="container">
      <div className="row">
        <div className="col-md-6 col-xl-5 d-flex align-items-md-end justify-content-center pb-lg-0 pb-4">
          <BannerWithSmallImageImage
            className="mb-lg-n4"
            image={fields.image}
            alt="AddComm"
            loading="eager"
          />
        </div>
        <div className="col-md-7 col-xl-6 pl-lg-5 pt-lg-lg-5 mb-5">
          <BannerWithSmallImageDescription content={fields.description} />
        </div>
      </div>
    </div>
  </StyledBannerWithSmallImage>
)

export default BannerWithSmallImage
