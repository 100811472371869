import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'

const StyledText = styled.section``

const TextDescription = styled(ParseContent)`
  & h3 {
    @media (min-width: 992px) {
      font-size: ${({ theme }) => theme.font.size.xl};
      line-height: ${({ theme }) => theme.font.size[40]};
    }

    @media (max-width: 991px) {
      font-size: ${({ theme }) => theme.font.size.l};
      line-height: ${({ theme }) => theme.font.size[28]};
    }
  }

  & iframe {
    @media (max-width: 991px){
      height: 220px;
    }
  }
`

interface TextProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const Text: React.FC<TextProps> = ({ fields }) => (
  <StyledText className="my-1 my-lg-5">
    <div className="container py-5">
      <TextDescription content={fields?.description} />
    </div>
  </StyledText>
)

export default Text
