/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import styled, { css } from 'styled-components'

// Elements
import Image from 'components/shared/Image2'
import ParseContent from 'components/shared/ParseContent'

import screen from 'img/screen.jpeg'

const StyledCustomVideoTitle = styled.section`
  position: relative;
  background-color: ${(props) => props.theme.color.face.main};

  @media (min-width: 992px){
    margin-top: -6rem !important;
  }

  @media (max-width: 991px){
    margin-top: -4rem !important;
  }
`

const CustomTextCTAContainer = styled.div`
  position: relative;
  z-index: 2;
`

const TitleVideo = styled(ParseContent)`
  position: relative;
  z-index: 2;

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & p,
  & strong {
    color: ${({ theme }) => theme.color.text.light};
  }

  @media (min-width: 992px) {
    & h1 {
      margin-top: 1.75rem;
      font-size: ${({ theme }) => theme.font.size[40]};
    }

    & h2 {
      margin-top: 1.75rem;
      font-size: ${({ theme }) => theme.font.size.l};
    }
  }

`

const CustomTextCTADescription = styled(ParseContent)`
  & a {
    text-decoration: underline;
  }

  & .button a {
    text-decoration: none;
  }
`
const BannerWithVideoContent = styled.video`
  object-fit: cover;
  overflow: hidden;
  position: relative;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
  width: 100%;

  @media (min-width: 992px) {
    height: 350px;
  }

  @media (max-width: 575px) {
    height: 250px;
  }
`

const BannerWithVideoDescription = styled(ParseContent)`
   & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & p,
  & strong {
    color: ${({ theme }) => theme.color.text.light};
  }

  @media (max-width: 575px) {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5 {
      font-size: ${(props) => props.theme.font.size.l};
      line-height: ${(props) => props.theme.font.size.l};
    }
  }

  @media (min-width: 992px) {
    & h1 {
      margin-top: 1.75rem;
      font-size: ${({ theme }) => theme.font.size[40]};
    }

    & h2 {
      margin-top: 1.75rem;
      font-size: ${({ theme }) => theme.font.size.l};
    }
  }

  @media (max-width: 991px) {
    font-weight: ${(props) => props.theme.font.weight.m};

    & p,
    & h1,
    & h2,
    & strong {
      color: ${(props) => props.theme.color.text.light};
    }
  }
`

const Mute = styled.button`
  background-color: ${({ theme }) => theme.color.face.light};
  border-radius: 50%;
  width: 35px;
  height: 35px;
  position: absolute;
  right: 15px;
  bottom: 25px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  font-size: 0;

  & svg {
    width: 20px;
    height: 20px;

    & path,
    & polygon,
    & line {
      fill: ${({ theme }) => theme.color.text.main};
    }
  }
`

const AutoPlay = styled.button`
  position: absolute;
  left: 10%;
  top: 10%;
  width: 80%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0;

  & svg {
    width: 80px;
    height: 80px;
  }
`

interface TitleWithVideoProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_TitleWithVideo
  location: any
}

const TitleWithVideo: React.FC<TitleWithVideoProps> = ({ fields, location }) => {

  const urlParams = new URLSearchParams(location.search)
  const autoplay: boolean =
    !urlParams.get('autoplay') || urlParams.get('autoplay') !== 'no'
  const [play, setPlay] = React.useState<boolean>(false)
  const [isMuted, setMuted] = React.useState<boolean>(autoplay)
  const videoId: string = (fields?.urlVideo || fields?.urlVideo) as string

  React.useEffect(() => {
    if (!autoplay) {
      const video = document.getElementById(videoId) as HTMLVideoElement

      video.onloadeddata = () => {
        setTimeout(() => {
          video?.pause()
        }, 100)
      }
    }
  }, [autoplay])

  return (
  <StyledCustomVideoTitle>
    <CustomTextCTAContainer className="container py-5 position-relative">
      <div className="row">
        <div className="col-12 mt-3 mt-lg-0 position-relative d-flex align-items-center justify-content-center">
          <TitleVideo
            className="mt-4 text-uppercase"
            content={fields.title}
          />
        </div>
        <div className="col-12 d-flex align-items-center justify-content-center">
          <div className="position-relative">
              <BannerWithVideoContent
                controls={true}
                loop
                id={videoId}
                poster={screen}
              >
                <track kind="captions" />
                <source src={videoId} type="video/mp4" />
              </BannerWithVideoContent>

              <AutoPlay
                type="button"
                onClick={() => {
                  const video = document.getElementById(
                    videoId
                  ) as HTMLVideoElement

                  if (play) {
                    video.pause()
                  } else {
                    video.play()
                    video.muted = false
                    setMuted(false)
                  }

                  setPlay(!play)
                }}
              >
                {!play && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <circle cx="12" cy="12" r="10" />
                    <polygon points="10 8 16 12 10 16 10 8" />
                  </svg>
                )}
                Afspelen
              </AutoPlay>
            </div>
          </div>
      </div>
    </CustomTextCTAContainer>
  </StyledCustomVideoTitle>
  )
}

export default TitleWithVideo
