import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'

const StyledBannerWithImage = styled.section`
  background-color: ${(props) => props.theme.color.face.main};

  @media (min-width: 992px) {
    padding-top: 150px;
  }

  @media (max-width: 991px) {
    padding-top: 125px;
  }
`

const BannerWithImageDescription = styled(ParseContent)`
  color: ${(props) => props.theme.color.text.light};
`

const BannerWithImageVideoWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
  overflow: hidden;

  @media (min-width: 992px) {
    height: 525px;
    width: 525px;
  }

  @media (max-width: 991px) {
    height: 350px;
    width: 350px;
  }

  @media (max-width: 575px) {
    height: 300px;
    width: 300px;
  }
`

const BannerWithImageVideo = styled.video`
  object-fit: cover;
  overflow: hidden;
  position: relative;
  z-index: 1;
  height: 90%;
  width: 100%;
`

const BannerWithImageVideoOverlay = styled.svg`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 2;

  & path.background {
    fill: ${({ theme }) => theme.color.face.main};
  }
`

interface BannerWithImageProps {
  className?: string
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_BannerWithImage
}

const BannerWithImage: React.FC<BannerWithImageProps> = ({
  className = '',
  fields,
}) => (
  <StyledBannerWithImage className={className}>
    <div className="container">
      <div className="row">
        <div className="col-md-6 pt-lg-5 pb-md-5 mb-3">
          <BannerWithImageDescription
            className="pb-md-5 mb-md-5"
            content={fields.description}
          />
        </div>
        <div className="col-md-6 d-md-block d-flex align-items-center justify-content-center py-md-0 py-5">
          <BannerWithImageVideoWrapper>
            <BannerWithImageVideo
              autoPlay
              controls={false}
              muted
              loop
              playsInline
            >
              <track kind="captions" />
              <source
                src={fields.videoUrl || fields.video_url}
                type="video/mp4"
              />
            </BannerWithImageVideo>
            <BannerWithImageVideoOverlay
              id="Laag_1"
              data-name="Laag 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1000 1000"
            >
              <path
                className="background"
                d="M0,0V1000H1000V0ZM877.15,562.16C857.6,612,826.78,657.21,785.54,696.63l-.61.57L461,993.84V813.5C367.77,804.66,281.27,764.07,214.18,697,137.86,620.66,95.83,519.19,95.83,411.25s42-209.41,118.35-285.73S392,7.16,499.91,7.16s209.41,42,285.74,118.36S904,303.31,904,411.25C904,466,895,516.79,877.15,562.16Z"
              />
              <path
                fill="#00def1"
                d="M461.05,994V813.62c-93.28-8.84-179.81-49.45-246.91-116.55C137.8,620.73,95.76,519.23,95.76,411.27s42-209.46,118.38-285.8S392,7.09,499.94,7.09s209.46,42,285.8,118.38,118.39,177.84,118.39,285.8c0,54.78-9,105.57-26.86,151-19.55,49.82-50.38,95.07-91.63,134.51l-.61.56ZM499.94,84.87c-180,0-326.4,146.42-326.4,326.4S320,737.67,499.94,737.67h38.9v79.62L732.17,640.22c63.37-60.71,94.17-135.61,94.17-229C826.34,231.29,679.92,84.87,499.94,84.87ZM758.76,668.61h0Z"
              />
            </BannerWithImageVideoOverlay>
          </BannerWithImageVideoWrapper>
        </div>
      </div>
    </div>
  </StyledBannerWithImage>
)

export default BannerWithImage
