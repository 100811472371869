import React from 'react'
import styled from 'styled-components'

// Elements
import Image from 'components/shared/Image2'
import ParseContent from 'components/shared/ParseContent'

const StyledDescriptionWithImage = styled.section`
  position: relative;
`

const DescriptionWithImageDescription = styled(ParseContent)`
  color: ${(props) => props.theme.color.text.purple};
`

const DescriptionWithImageBackgroundLeft = styled.div`
  background-color: ${(props) => props.theme.color.face.background};
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;

  @media (min-width: 768px) {
    width: 50%;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`

const DescriptionWithImageBackground = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
`

const DescriptionWithImageBackgroundRight = styled.div`
  background-color: ${(props) => props.theme.color.face.background};
  height: 100%;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
`

const DescriptionWithImageImage = styled(Image)`
  & img {
    object-fit: contain !important;
  }

  @media (min-width: 1200px) {
    width: 550px;
    height: 550px;
  }

  @media (min-width: 992px) {
    position: absolute !important;
    left: 50px;
    top: -75px;
    width: 450px;
    height: 450px;
  }

  @media (max-width: 991px) {
    left: 50px;
    top: 0;
    width: 100%;
    width: 300px;
    height: 300px;
  }

  @media (max-width: 767px) {
    left: 0;
    width: 200px;
    height: 200px;
  }

  @media (max-width: 575px) {
    left: 0;
    width: 175px;
    height: 175px;
  }
`

interface DescriptionWithImageProps {
  fields: any
}

const DescriptionWithImage: React.FC<DescriptionWithImageProps> = ({
  fields,
}) => (
  <StyledDescriptionWithImage className="my-1 my-lg-5">
    <DescriptionWithImageBackgroundLeft />
    <DescriptionWithImageBackground>
      <div className="row h-100 d-md-flex d-none">
        <div className="col-12 col-md-10 col-xl-8 h-100">
          <DescriptionWithImageBackgroundRight />
        </div>
      </div>
    </DescriptionWithImageBackground>
    <div className="container py-5">
      <div className="row">
        <div className="col-sm-8 col-md-7 col-lg-6">
          <DescriptionWithImageDescription content={fields.description} />
        </div>
        <div className="col-sm-4 col-md-5 col-lg-6 position-relative mt-md-0 mt-5 d-flex justify-content-center">
          <DescriptionWithImageImage
            className="mx-auto"
            image={fields.image}
            alt="AddComm"
          />
        </div>
      </div>
    </div>
  </StyledDescriptionWithImage>
)

export default DescriptionWithImage
