import React from 'react'
import styled, { css } from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Image from 'components/shared/Image2'

const StyledBannerWithImg = styled.section<{ styletype: string }>`
  position: relative;

  ${(props) =>
    props.styletype === 'default' &&
    css`
      background-color: ${({ theme }) => theme.color.face.purple};

      @media (min-width: 992px) {
        padding-top: 150px;
      }

      @media (max-width: 991px) {
        padding-top: 100px;
      }
    `}

  ${(props) =>
    props.styletype === 'dark' &&
    css`
      background-color: ${({ theme }) => theme.color.face.main};

      @media (min-width: 992px) {
        padding-top: 100px;
      }

      @media (max-width: 991px) {
        padding-top: 75px;
      }
    `}
`

const BannerWithImgMedia = styled(Image)`
  width: 100%;

  @media (min-width: 992px) {
    height: 85%;
  }

  @media (max-width: 991.98px) {
    height: 250px;
  }

  @media (max-width: 767.98px) {
    height: 225px;
  }

  @media (max-width: 575.98px) {
    height: 175px;
  }
`

const BannerWithImgDescription = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & p,
  & strong {
    color: ${({ theme }) => theme.color.text.light};
  }

  & h1 {
    & img {
      width: 50px;
    }
  }

  @media (max-width: 575px) {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5 {
      font-size: ${(props) => props.theme.font.size.l};
      line-height: ${(props) => props.theme.font.size.l};
    }
  }

  @media (min-width: 992px) {
    & h1 {
      margin-top: 1.75rem;
      font-size: ${({ theme }) => theme.font.size[40]};
    }

    & h2 {
      margin-top: 1.75rem;
      font-size: ${({ theme }) => theme.font.size.l};
    }
  }

  @media (max-width: 991px) {
    font-weight: ${(props) => props.theme.font.weight.m};

    & p,
    & h1,
    & h2,
    & strong {
      color: ${(props) => props.theme.color.text.light};
    }
  }
`

interface BannerWithImgProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_BannerWithImg
}

const BannerWithImg: React.FC<BannerWithImgProps> = ({ fields }) => (
  <StyledBannerWithImg styletype={fields?.styletype || 'default'}>
    <div className="container py-lg-5 py-4">
      <div className={`row${fields?.styletype === 'dark' ? '' : ' mb-lg-5'}`}>
        <div
          className={`${
            fields?.styletype === 'dark' ? 'col-md-6' : 'col-md-5'
          } d-flex align-items-center pr-lg-5 order-2`}
        >
          <BannerWithImgDescription content={fields?.description || ''} />
        </div>
        <div
          className={`${
            fields?.styletype === 'dark' ? 'col-md-6' : 'col-md-7'
          } d-flex align-items-center justify-content-center order-md-3 order-1 mb-md-0 mb-4`}
        >
          <BannerWithImgMedia
            alt="AddComm"
            image={fields?.image}
            loading="eager"
          />
        </div>
      </div>
    </div>
  </StyledBannerWithImg>
)

export default BannerWithImg
