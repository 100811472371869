import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Image from 'components/shared/Image2'

import LinkedIn from 'img/linkedin.inline.svg'

const StyledTeam = styled.section``

const EmployeesWrapper = styled.div`
  /* @media (min-width: 992px) {
    & > div {
      flex: 0 0 20% !important;
      max-width: 20% !important;
    }
  } */
`

interface TeamProps {
  fields: {
    description: string
    employees: Array<any>
  }
}

const Team: React.FC<TeamProps> = ({ fields }) => (
  <StyledTeam className="my-5">
    <div className="container pt-lg-5 pb-4">
      <ParseContent content={fields?.description} />
      <EmployeesWrapper className="row mt-3 mt-lg-5">
        {fields?.employees?.map((edge: any, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} className="col-12 col-sm-6 col-lg-4 col-xl-3 my-5 py-lg-2">
            <Employee fields={edge} />
          </div>
        ))}
      </EmployeesWrapper>
    </div>
  </StyledTeam>
)

const StyledEmployee = styled.div`
  width: 100%;
  padding-top: 100%;
  border-radius: 25px;
  overflow: visible;
  position: relative;
  z-index: 1;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);

  @media (max-width: 991px) {
    padding-top: 50%;
  }
`

const EmployeeImage = styled(Image)`
  position: absolute !important;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 30px;
`

const EmployeeInfo = styled.div`
  background: ${props => props.theme.color.face.border};
  z-index: 9;
  margin-right: -15px;
  height: 77px;
  right: 0;
  bottom: -50px;
  width: 246px;
  border-radius: 14px;

  @media (max-width: 991px) {
    width: 100%;
    position: relative !important;
    margin: 0 auto;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`

const Content = styled(ParseContent)`
  & p {
    font-size: 14px;
    line-height: 22px;

    & strong {
      text-transform: uppercase;
    }
  }
`

interface EmployeeProps {
  fields: {
    image: any
    name: any
    linkedinUrl: any
  }
}

const Employee: React.FC<EmployeeProps> = ({ fields }) => (
  <StyledEmployee>
    <EmployeeImage image={fields?.image} alt="AddComm" />
    <EmployeeInfo className="position-absolute px-3 d-flex align-items-center justify-content-between">
      <Content className="" content={fields?.name} />
      <a href={fields?.linkedinUrl} rel="noreferrer" target="_blank">
        <LinkedIn />
      </a>
    </EmployeeInfo>
  </StyledEmployee>
)

export default Team
