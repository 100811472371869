/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import styled from 'styled-components'

// Elements
import CustomLink from 'components/shared/CustomLink'
import Image from 'components/shared/Image2'
import ParseContent from 'components/shared/ParseContent'

// Slider
import SliderTrack from 'react-slick'

const StyledSlider = styled.section`
  & .slick-prev:before,
  & .slick-next:before {
    font-size: ${({ theme }) => theme.font.size.xl};
    color: ${({ theme }) => theme.color.text.main};
  }
`

const SliderSlideImage = styled(Image)`
  width: 100%;
  height: 100px;
  max-height: 100px;
  max-width: 175px;

  & img {
    object-fit: contain !important;
  }
`

const SliderContent = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    @media (min-width: 992px) {
      font-size: ${(props) => props.theme.font.size.xl} !important;
    }

    @media (max-width: 991px) {
      font-size: ${(props) => props.theme.font.size.l} !important;
    }
  }
`

const SliderSlideDivider = styled.span`
  background-color: ${(props) => props.theme.color.face.border};
  position: absolute;
  right: 0;
  width: 2px;
  height: 50px;
`

const SliderSlides = styled.div`
  & > div:last-child ${SliderSlideDivider} {
    display: none;
  }
`

const Slide = styled(CustomLink)`
  font-size: 0;
`

interface SliderProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Slider
}

const Slider: React.FC<SliderProps> = ({ fields }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 250,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    accessibility: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  }

  return (
    <StyledSlider className="my-1 my-lg-5">
      <div className="container py-5">
        <SliderContent content={fields?.description} />
        <SliderSlides className="mt-3">
          <SliderTrack {...settings}>
            {fields?.slides?.map((edge, index: number) => (
              <Slide
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className="d-flex justify-content-center align-items-center"
                to={edge?.link?.url || '/'}
                target={edge?.link?.target}
              >
                <SliderSlideImage image={edge?.icon} alt={edge?.link?.title} />
                {edge?.link?.title}
                {index + 1 !== fields?.slides?.length && <SliderSlideDivider />}
              </Slide>
            ))}
          </SliderTrack>
        </SliderSlides>
      </div>
    </StyledSlider>
  )
}

export default Slider
