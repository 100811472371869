import React from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Image2 from 'components/shared/Image2'
import ButtonOrangeBigBorder from 'components/elements/ButtonOrangeBigBorder'
import Form from 'components/related/Form'

// Context
import { VacancyContext } from 'templates/vacancy'

const FixedElement = styled.div<{ sticky: boolean }>`
  display: ${(props) => (props.sticky ? 'block' : 'none')};

  @media (min-width: 992px) {
    height: 94px;
  }

  @media (max-width: 991.98px) {
    height: 68px;
  }
`

const FixedStickyCta = styled.section<{ sticky: boolean }>`
  background-color: ${({ theme }) => theme.color.face.main};
  position: ${(props) => (props.sticky ? 'fixed' : 'relative')};
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;

  & .button {
    margin-bottom: 0;

    & > a {
      color: inherit;
    }
  }
`

const CtaButtons = styled.div`
  position: relative;

  @media (max-width: 1399px) {
    right: 100px;
  }
`

const CtaContent = styled(ParseContent)`
  position: relative;

  @media (max-width: 575.98px) {
    & .button[color='orange'] {
      display: none;
    }
  }
`

const CtaImage = styled(Image2)`
  position: absolute !important;
  bottom: 1.5rem;

  @media (min-width: 1400px) {
    right: -100px;
  }

  @media (min-width: 992px) {
    width: 90px;
  }

  @media (max-width: 991.98px) {
    width: 70px;
  }
`

const CtaButton = styled(ButtonOrangeBigBorder)`
  & button {
    color: ${({ theme }) => theme.color.text.light};

    @media (min-width: 992px) {
      padding: 9px 30px;
    }

    @media (max-width: 991.98px) {
      padding: 4px 15px;
    }
  }
`

const FixedForm = styled.div`
  background-color: ${({ theme }) => theme.color.face.light};
  border: 2px solid ${({ theme }) => theme.color.face.secondary};
  position: absolute;
  bottom: 100%;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  box-shadow: 0 5px 25px rgb(0 0 0 / 10%);

  & .form-duuf-group {
    margin-bottom: 1.5rem;
  }

  & .form-duuf-group:last-child {
    margin-bottom: 0;
  }

  @media (min-width: 1450px) {
    right: -125px;
  }

  @media (max-width: 1449px) {
    right: 0;
  }

  @media (min-width: 576px) {
    width: 350px;
  }

  @media (max-width: 575.98px) {
    right: 15px;
    width: 350px;
    max-width: 90%;
  }
`

const FixedFormExit = styled.button`
  position: absolute;
  top: -40px;
  right: 0;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.face.contrast};
  box-shadow: 0 5px 15px rgb(0 0 0 / 10%);

  &:hover {
    opacity: 0.5;
  }

  & svg {
    width: 20px;
    height: 20px;

    & path {
      fill: ${({ theme }) => theme.color.text.main};
    }
  }
`

interface StickyCtaProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_StickyCta
}

const StickyCta: React.FC<StickyCtaProps> = ({ fields }) => {
  const vacancyContext = React.useContext(VacancyContext)
  const [isSticky, setSticky] = React.useState(true)
  const [showForm, setShowForm] = React.useState(false)
  const stickyRef = React.useRef<HTMLDivElement>(null)

  const handleScroll = () => {
    const footerHeight = document?.getElementById('footer')?.clientHeight || 0
    const documentHeight = document.body.scrollHeight
    const currentScroll = window.scrollY + window.innerHeight

    if (currentScroll + footerHeight >= documentHeight && isSticky) {
      setSticky(false)
    } else if (currentScroll + footerHeight < documentHeight && !isSticky) {
      setSticky(true)
    }
  }

  React.useEffect(() => {
    handleScroll()

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [handleScroll])

  return (
    <>
      <FixedElement sticky={isSticky} />
      <FixedStickyCta sticky={isSticky} ref={stickyRef}>
        <div className="container position-relative">
          <AnimatePresence>
            {showForm && fields.formid && (
              <motion.div
                exit={{
                  opacity: 0,
                }}
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                }}
              >
                <FixedForm className="p-3 p-sm-4">
                  <FixedFormExit
                    type="button"
                    onClick={() => {
                      setShowForm(!showForm)
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-x"
                    >
                      <line x1="18" y1="6" x2="6" y2="18" />
                      <line x1="6" y1="6" x2="18" y2="18" />
                    </svg>
                  </FixedFormExit>
                  <Form
                    id={fields.formid}
                    defaultFields={{ input_4: vacancyContext.title }}
                    privacyUrl="/privacyverklaring"
                    privacyUrlPlacement="privacybeleid"
                    scrollToConfirm={false}
                  />
                </FixedForm>
              </motion.div>
            )}
          </AnimatePresence>

          <div className="py-3 py-lg-4 justify-content-end d-flex">
            <CtaButtons className="d-flex align-items-center">
              <CtaContent content={fields.description} />
              <CtaButton className="ml-2" to="" isCustom>
                <button
                  id="sticky-cta-button"
                  type="button"
                  onClick={() => {
                    setShowForm(!showForm)
                  }}
                >
                  {fields.buttontext}
                </button>
              </CtaButton>
            </CtaButtons>
            <CtaImage image={fields?.imagae} />
          </div>
        </div>
      </FixedStickyCta>
    </>
  )
}

export default StickyCta
